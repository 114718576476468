@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@700&family=Roboto&display=swap');

.App {
  text-align: center;
}

.App-logo {
  display: flex;
  width: 100%;
  pointer-events: none;
}

.App-bar .MuiTypography-h3 {
  padding: 5px;
  margin: 5px;
}

.App-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  color: rgb(116, 118, 126);
  font-size: 25px;
}

.App-social-icon {
  padding: 4px;
  margin: 4px;
}

.App-subtitle h3 {
  font-style: italic;
  font-size: 35px;
  color: #D1C4E9;
}

.App-link {
  color: #61dafb;
}

footer {
  text-align: center;
}